<template>
  <v-app style="background-color: #eff1f5;">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    customBackgroundColor: "#f3f3f3",
    backgroundColor: "#d1e8ff",
  }),
  watch: {},
  mounted() {},
};
</script>
<style>
@import "./css/styles.css";

.ScrollbarWidth ::-webkit-scrollbar {
  width: none; /* Width of the scrollbar */
}

.ScrollbarWidth ::-webkit-scrollbar-thumb {
  background-color: #fac62a; /* Custom color */
  border-radius: 10px; /* Rounded edges */
  border: 3px solid #fff; /* Padding around the thumb */
}

.ScrollbarWidth ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.ScrollbarWidth {
  scrollbar-width: none !important;
  scrollbar-color: #fac62a #f5f5f5 !important;
  overflow: hidden;
}
.AppBackgroundVariant1 {
  background: linear-gradient(90deg, #13181f, #526785) !important;
}
.FontFamilyVariant1 {
  width: 355px !important;
  height: auto !important;
  color: #13181f;
  font-family: "Libre Baskerville";
  font-size: 28px;
  font-style: normal;
  display: flex;
  gap: 20px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -1.12px;
}
.FontFamilyVariant2 {
  width: 355px !important;
  height: auto !important;
  align-self: stretch;
  color: #5d616d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  padding-top: 10px !important;
}
.FontFamilyVariant3 {
  width: 355px !important;
  height: auto !important;
  color: #13181f;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
  padding-top: 30px !important;
}
.FontFamilyVariant4 {
  width: 355px !important;
  height: auto !important;
  color: #13181f;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
}
.FontFamilyVariant5 {
  height: auto !important;
  color: #9ca3ab;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
}
.FontFamilyVariant6 {
  color: #5d616d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  padding-top: 20px;
}
.FontFamilyVariant7 {
  width: 355px !important;
  height: auto !important;
  color: #13181f;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
}
.FontFamilyVariant8 {
  max-width: 355px !important;
  height: auto !important;
  color: #13181f;
  align-self: flex-end;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
}
.CardVariant1 {
  border-radius: 4px !important;
}
.paddingVariant1 {
  padding: 30px 20px 30px 20px !important;
}
.field_height1.v-text-field .v-input__control .v-input__slot {
  width: 355px !important;
  display: flex;
  min-height: 44px;
  max-height: 44px;
  padding: 14px 10px !important;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  /* border: 1px solid var(--Blue, #002992); */
  background: #fff;
}
.BtnVariant1 .v-btn__content {
  display: block;
  font-family: Inter !important;
}

.valid-password {
  color: green !important;
}

.invalid-password {
  color: red !important;
}
.FontSize9px {
  font-size: 0.5625rem !important;
}
.FontSize10px {
  font-size: 0.625rem !important;
}
.FontSize11px {
  font-size: 0.6875rem !important;
}
.FontSize12px {
  font-size: 0.75rem !important;
}
.FontSize15px {
  font-size: 0.9375rem !important;
}
.FontSize16px {
  font-size: 1rem !important;
}
.FontSize18px {
  font-size: 1.125rem !important;
}
.FontSize20px {
  font-size: 1.25rem !important;
}
.FontSize22px {
  font-size: 1.375rem !important;
}
.FontSize25px {
  font-size: 1.5625rem !important;
}
.FontSize30px {
  font-size: 1.875rem !important;
}
.FontSize40px {
  font-size: 2.5rem !important;
}
.FontSize45px {
  font-size: 2.8125rem !important;
}

.MaxWidthVariant1 {
  max-width: 21.875rem !important;
}
.MaxWidthVariant2 {
  max-width: 37.5rem !important;
}
.MaxWidthVariant3 {
  max-width: 46.875rem !important;
}
.MaxWidthVariant4 {
  max-width: 34.375rem !important;
}
.MaxWidthVariant5 {
  max-width: 9.375rem !important;
}
.MaxWidthVariant6 {
  max-width: 15.625rem !important;
}
.MaxHeightVarient1 {
  max-height: 25rem !important;
}
.FontWeightVariant1 {
  font-weight: bolder !important;
}
.RoundBorderVarient1 {
  border-radius: 0.625rem !important;
}
.LetterSpacing2px {
  letter-spacing: 0.125rem !important;
}
.fontStyleVarient1 {
  font-family: Arial, Helvetica, sans-serif !important;
}
.image-upload-input {
  display: none;
  z-index: -9999;
}
.cursorPointer {
  cursor: pointer !important;
}
.FontVarient1 {
  font-family: "Inter", sans-serif !important;
  font-size: 2.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 100% !important; /* 2.75rem */
  letter-spacing: -0.0825rem !important;
}
.FontVarient2 {
  font-family: "Inter", sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.FontVarient3 {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important; /* 19.0.125rem */
  letter-spacing: -0.03rem !important;
}
.FontVarient4 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important; /* 21.0.375rem */
  letter-spacing: -0.03375rem !important;
  opacity: 0.8 !important;
}
.FontVarient5 {
  text-align: center !important;
  font-family: "Inter", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important; /* 14.0.25rem */
  letter-spacing: -0.0225rem !important;
}
.FontVarient6 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.045rem !important;
}
.FontVarient7 {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.045rem !important;
}
.FontVarient8 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}
.ButtonVarient1 {
  width: 7.3125rem !important;
  height: 3.5rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.625rem;
}
.ButtonVarient2 {
  max-width: 10.0625rem !important;
  height: 3.5rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.625rem;
}
.ButtonVarient3 {
  max-width: 16.125rem !important;
  height: 3.5rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.625rem;
}
.ButtonVarient4 {
  max-width: 10.0625rem !important;
  height: 2.1875rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.625rem;
}
.ButtonVarient5 {
  width: 6.25rem !important;
  height: 2.875rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.625rem;
}
.ButtonVarient6 {
  display: flex !important;
  width: 9.25075rem !important;
  height: 3rem !important;
  padding: 1rem 1.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;
  flex-shrink: 0 !important;
  border-radius: 2.5rem !important;
}
.LandingPageCardAlignMent1 {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
  line-height: 2.8125rem !important;
  margin-top: 1.25rem !important;
  margin-left: 5rem !important;
  color: #ffffff !important;
}
.LandingPageCardAlignMent2 {
  padding-top: 0.9375rem !important;
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
  opacity: 0.8 !important;
}
.RoundedCardVarient {
  border-radius: 2.5rem !important;
}
.RoundedCardVarient1 {
  border-radius: 0.625rem !important;
}
.blueBoxCardVariant {
  margin-left: 15% !important;
  margin-right: 15% !important;
}
.CardVarient1 {
  border-radius: 2.5rem 2.5rem 2.5rem 2.5rem !important;
}

.CardVarient2 {
  /* margin-top: -40% !important; */
  border-top: 0.5rem solid #7cefb8 !important;
  border-left: 0.5rem solid #7cefb8 !important;
}
.CardVarient3 {
  margin-left: 28% !important;
  padding-top: 0.75rem !important;
}
.CardVarient4 {
  margin-left: 28% !important;
}
.CardVarient5 {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  overflow: hidden;
}
.CardVarient6 {
  position: absolute !important;
  left: 0 !important;
  top: 25rem !important;
  margin-left: -5.625rem !important;
  max-height: 31.25rem !important;
  transform: rotate(35deg) !important;
  overflow: hidden;
}
.CardVarient7 {
  border-radius: 2.5rem 2.5rem 2.5rem 2.5rem !important;
  left: 0 !important;
  padding-left: -10% !important;
}
.CardVarient8 {
  padding-top: 70% !important;
}
.CardVarient9 {
  display: flex !important;
  padding: 2.5rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 1rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  border-radius: 0.5rem !important;
  align-self: stretch !important;
}
.floating-div {
  position: fixed !important;
  top: 1.25rem !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 9999 !important;
}
.floating-button {
  position: fixed !important;
  top: 1.25rem !important;
  right: 1.25rem !important;
  z-index: 999 !important;
}
.resourceInsideCard {
  border-radius: 3.75rem !important;
  background: #fff !important;
  height: 75rem !important;
}
.insightsTitle {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important; /* 21.0.375rem */
  letter-spacing: -0.03375rem !important;
}
.resoucesAndinsightsDescription {
  overflow: hidden !important;
  color: #707070 !important;
  text-overflow: ellipsis !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.0225rem !important;
}
.chatCardStyle {
  max-height: 12.5rem !important;
  overflow: "auto" !important;
}
.chipClass {
  display: flex !important;
  height: 3.5rem !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;
  border-radius: 2.5rem !important;
}
.square-avatar {
  border-radius: 0 !important; /* Remove border-radius to make it square */
  width: 9.375rem !important; /* Set the desired width */
  height: 9.375rem !important; /* Set the desired height */
}

.square-avatar2 {
  border-radius: 0 !important; /* Remove border-radius to make it square */
  width: 7.8125rem !important; /* Set the desired width */
  height: 7.8125rem !important; /* Set the desired height */
}
.square-avatar-small {
  border-radius: 0 !important; /* Remove border-radius to make it square */
  width: 1.5625rem !important; /* Set the desired width */
  height: 1.5625rem !important; /* Set the desired height */
}
.chipClassText {
  color: var(--primary-royal-blue, #0354e0) !important;
  text-align: center !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.03rem !important;
}
.fontFamilyInter {
  font-family: "Inter ", sans-serif !important;
}
.LandingPageToolBarContent {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  font-family: "Inter", sans-serif !important;
  box-shadow: none !important;
  color: #000 !important;
  gap: 5rem !important;
  margin-top: 1.875rem !important;
}
.LandingPageToolBar {
  width: 97% !important;
  height: 5.125rem !important;
  flex-shrink: 0 !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  background: #fff !important;
  gap: 5rem !important;
  z-index: 1000 !important;
  margin: 3.75rem 1.25rem 3.75rem !important;
}
.BankLogoImage {
  color: #2889e5 !important;
  text-align: center !important;
  font-family: "Russo One", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 1.875rem !important;
}
.XchangeLogoImage {
  color: #0354e0 !important;
  font-family: "Russo One", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.TableVarient table {
  border-spacing: 0 0.125rem !important;
  /* margin-top: -1.875rem !important; */
}
.TableVarient th {
  background: #ffffff !important;
  font-size: 1.125rem !important;
  color: #000 !important;
  border-bottom: 0.0625rem solid #0354e0 !important;
}
.TableVarient td {
  gap: 1.75rem !important;
  padding: 0.75rem !important;
}
.avatar-container {
  position: relative !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}
.avatar {
  position: absolute !important;
  width: 0.625rem !important;
  height: 0.625rem !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1 !important;
}
.eventCard {
  display: inline-flex !important;
  height: 2.9375rem !important;
  padding: 0.374rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.187rem !important;
  flex-shrink: 0 !important;
  border-radius: 0.25rem !;
  background: var(--secondary-navy, #003a9f) !important;
}
.resourceImageClass {
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.fontStyleVariant21 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  /* line-height: normal !important;
  letter-spacing: -0.045rem !important; */
}
.fontStyleVariant20 {
  color: var(--primary-royal-blue, #0354e0) !important;
  text-align: right;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant13 {
  color: #2f2f2f !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03375rem !important;
}
.discussionCard {
  display: flex !important;
  padding: 1.25rem !important;
  flex-direction: column !important;
  gap: 1rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  background: #fff !important;
}
.gapVariant4 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  gap: 1rem !important;
}

.loginPageCard1 {
  max-width: 27.0625rem !important;
}
.bankLabel {
  color: #2889e5 !important;
  text-align: center !important;
  font-family: "Russo One", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.landingPageSubLabel1 {
  color: var(--primary-white, #fff) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.landingPageMainLabel {
  color: #fff !important;
  margin-top: 1.25rem !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2.175rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  padding: 0.625rem !important;
  width: 60% !important;
  letter-spacing: -0.0825rem !important;
}
.XchangeLabel {
  color: #0354e0 !important;
  font-family: "Russo One", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.loginCardToolbar {
  height: 5.125rem !important;
  flex-shrink: 0 !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  background: #fff !important;
}
.landingPageImageCard {
  display: inline-flex !important;
  padding-left: 0 !important;
  gap: 6.875rem !important;
  border-radius: 0.5rem !important;
  background: #003a9f !important;
  height: 18.75rem !important;
}

.buttonClassVariant2 {
  color: var(--primary-royal-blue, #0354e0) !important;
  text-align: right;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.03rem !important;
}
.buttonClassVariant1 {
  display: flex !important;
  height: 3.5rem !important;
  font-family: "Inter", sans-serif !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2.5rem !important;
  background: var(--primary-royal-blue, #0354e0) !important;
}
.buttonClassVariant3 {
  display: flex !important;
  font-family: "Inter", sans-serif !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2.5rem !important;
}

.fontFamilyInter {
  font-family: "Inter ", sans-serif !important;
}

.insightsTableCard {
  display: inline-flex !important;
  padding: 6.25rem !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 3.75rem !important;
  border: 0.0625rem solid #d2d2d2 !;
  background: #fff !important;
  box-shadow: -0.75rem -0.75rem 0 0 #7cefb8 !important;
}
.loadingOutsideCard {
  display: flex !important;
  width: 24.375rem !important;
  padding: 2rem 0.75rem 3.5rem 0.75rem !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.75rem !important;
}
.loadingInsideCard {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
  align-self: stretch !important;
  height: 9.375rem !important;
  background: var(--primary-pearl, #f4f4f4) !important;
}
.gapVariant1 {
  display: flex !important;
  padding: 1.25rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}
.gapVariant2 {
  display: flex !important;
  padding: 1.875rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 2rem !important;
}
.gapVariant3 {
  display: flex !important;
  padding: 0.9375rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.resourceTableClass {
  border-top: 0.75rem solid#7cefb8 !important;
  border-left: 0.75rem solid#7cefb8 !important;
}
.negativeMargin {
  top: -18.75rem !important;
}
.fontStyleVariant16 {
  color: #707070 !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.surveyStatusCompleted {
  color: var(--tertiary-dark-green, #008545) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0.75rem !important;
}
.surveyCard {
  display: flex !important;
  padding: 0.625rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 1rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  background: #fff !important;
}
.custom-divider {
  background-color: #3e3d3d !important;
  height: 0.125rem !important;
}
.surveyStatusNotStarted {
  color: var(--secondary-slate, #707070) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0.75rem !important;
}

.fontStyleVariant26 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant25 {
  color: var(--secondary-slate, #707070) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.625rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 0.75rem !important;
}

.surveyStatusInProgress {
  color: var(--secondary-navy, #003a9f) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0.75rem !important;
}
.borderLeft {
  border-left: 0.0625rem solid gray !important;
}
.fontStyleVariant15 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVarient14 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 100% !important;
  letter-spacing: -0.0825rem !important;
}
.fontStyleVariant23 {
  color: #0354e0 !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant12 {
  color: #707070 !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.fontStyleVariant11 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.0375rem !important;
}
.fontStyleVariant10 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontStyleVariant22 {
  color: var(--primary-black, #ffffff) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontStyleVariant24 {
  color: var(--primary-black, #ffffff) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontStyleVariant19 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  text-align: center !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  letter-spacing: -0.03375rem !important;
}
.fontStyleVariant27 {
  color: #000 !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.375rem !important;
  font-style: normal !important;
  font-weight: 200 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontStyleVariant18 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  text-align: center !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 100% !important;
  letter-spacing: -0.0825rem !important;
}
.fontStyleVariant17 {
  color: #000 !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}
.fontStyleVariant9 {
  color: var(--primary-royal-blue, #0354e0) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  /* letter-spacing: -0.175rem !important; */
  cursor: pointer !important;
}
.fontStyleVariant8 {
  color: var(--tertiary-dark-green, #008545) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0525rem !important;
}
.fontStyleVariant7 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}

.fontStyleVariant6 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0375rem !important;
}
.fontStyleVariant5 {
  color: var(--secondary-slate, #707070) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.02625rem !important;
}
.fontStyleVariant4 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.0225rem !important;
}
.fontStyleVariant3 {
  color: var(--primary-royal-blue, #0354e0) !important;
  text-align: center !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}

.fontStyleVariant2 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important; /* 19.0.125rem */
  letter-spacing: -0.03rem !important;
}

.fontStyleVariant1 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0525rem !important;
}

.signUpPageCard {
  display: flex !important;
  padding: 2.5rem !important;
  flex-direction: column !important;
  gap: 2rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid var(--secondary-stone, #d2d2d2) !important;
  background: var(--primary-white, #fff) !important;
}
.loginCardGapVariant1 {
  gap: 2rem !important;
}
.loginCardTextVariant4 {
  color: var(--secondary-slate, #707070) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.02625rem !important;
}
.loginCardTextVariant3 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.loginCardTextVariant2 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100% !important; /* 19.0.125rem */
  letter-spacing: -0.03rem !important;
}
.loginCardTextVariant1 {
  color: var(--primary-black, #000) !important;
  font-family: "Inter ", sans-serif !important;
  font-size: 1.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 90% !important;
  letter-spacing: -0.0525rem !important;
}
.loginCard {
  display: flex !important;
  width: 29.5rem !important;
  height: 31.25rem !important;
  padding: 2.5rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 2rem !important;
  flex-shrink: 0 !important;
}

.TextUnderline {
  text-decoration: underline !important;
}
.video-container {
  position: relative;
  max-width: 100%;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.75rem;
  height: 3.75rem;
  background-color: rgba(129, 121, 121, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rounedImage {
  border: 1px solid black !important;
  background: #003a9f !important;
}
.conversationCard {
  border-radius: 60px !important;
}
.ConversationInitialCard {
  width: 40px !important;
  height: 40px !important;
}
/* Firefox */
.CardScroll {
  scrollbar-width: thin !important;
  scrollbar-color: #003a9f #cdcdcd;
}

/* Chrome, Edge, and Safari */
.CardScroll ::-webkit-scrollbar {
  height: 0.375rem !important;
  width: 0.375rem !important;
  scrollbar-color: #003a9f #cdcdcd;
}

.CardScroll ::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  scrollbar-width: thin !important;
  background-color: #003a9f !important;
  border-radius: 0.625rem;
  border: 0 solid #ffffff;
}
.backgroundVariant1 {
  background-color: #4c75bb !important;
  color: #fff !important;
}
.TextTransFormVariant1 .v-btn__content {
  text-transform: none !important;
}
.custom-disabled.v-btn--disabled {
  color: rgba(255, 255, 255, 0.833);
}
</style>
