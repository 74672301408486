import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    TokenDetails: {},
    GetCurrentUser: {},
    changeBgColor: false,
    DataStore: [],
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_bg_color: (state) => {
      return state.changeBgColor;
    },
    get_token_details: (state) => {
      return state.TokenDetails;
    },
    get_stored_data: (state) => {
      return state.DataStore;
    },
  },
  mutations: {
    SET_USEREMAIL(state, EmailID) {
      state.EmailID = EmailID;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_BG_COLOR(state, changeBgColor) {
      state.changeBgColor = changeBgColor;
    },
    SET_TOKEN_DETAILS(state, TokenDetails) {
      state.TokenDetails = TokenDetails;
    },
    SET_ALL_DATA_STORE_DETAILS(state, DataStore) {
      state.DataStore = DataStore;
    },
    CLEAR_DATA_STORE(state) {
      state.DataStore = [];
    },
  },
  actions: {},
  modules: {},
});

