import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        greyColorVarient1: "#f3f3f3",
        greyColorVarient2: "#d9d9d9",
        greyColorVarient3: "#9e9e9e",
        greyColorVariant4: "#707070",
        greyColorVarient5: "#f4f4f4",
        greyColorVarient6: "#eaeaea",
        greyColorVarient7: "#939393",
        greyColorVariant8: "#AFB5BB",
        blueColorVariant1: "#003a9f",
        blueColorVarient2: "#f1f8ff",
        blueColorVarient3: "#d1e8ff",
        blueColorVariant4: "#0354e0",
        blueColorVariant5: "#002992",
        blueColorVariant6: "#093195",
        blackColorVarient: "#3a3b3d",
        greenColorVarient: "#008545",
        greenColorVariant1: "#7cefb8",
        greenColorVarient2: "#008545",
        resourceBackground: "#D1E8FF",

        whiteColorVariant1: "#EFF1F5",

        greyColorVariant1: "#596068",

        redColorVariant1:"#ff1919",
        redColorVariant2:"#FF5C5C",
        redColorVariant3:"#ba0f30",

        blackColorVariant1:"13181F",
      },
    },
  },
});
